<template>
  <div class="section-hot">
    <div class="section-hot__title">
      <h3>Game hot</h3>
    </div>
    <div class="section-hot__content">
      <div
        v-for="(item, index) in gameHotData"
        :key="index"
        class="item-game"
        @click="navigationCheckLoggedInAndOpenGame(item)"
      >
        <BaseImg :src="item.img" :alt="item.name" lazy class="image-game" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useNavigationGame from '~/composables/useNavigationGame'
import { useGameHot } from '~/composables/home/useGameHot'
const { fetchListGameHot } = useGameHot()

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const { data: gameHotData } = await useAsyncData('gameHot', async () => {
  return await fetchListGameHot()
})
</script>

<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/section-hot/index.scss"></style>
