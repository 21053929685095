import { storeToRefs } from 'pinia'
import { useLobbyGameApi } from '~/api/lobby-game'
import { useAppStore } from '~/store/app'
import useModal from '~/composables/useModal'
import useNavigationGame from '~/composables/useNavigationGame.ts'
import { MODAL_TYPES } from '~/config/constant'

interface IBanner {
    name: string
    api_url: string
    img: string
    link?: string
}
export default () => {
  const { $axios, $pinia } = useNuxtApp()
  const { HOME_BANNER } = useLobbyGameApi()
  const store = useAppStore($pinia)
  const { currentUser } = storeToRefs(store)
  const { openModalWithNavigate } = useModal()
  const { openGameByUrl } = useNavigationGame()
  const router = useRouter()
  const bannerData = ref<IBanner[]>([])

  const handleClickBanner = (item: IBanner) => {
    if (item?.api_url.includes('partnerprovider') && item?.api_url.includes('partnergameid')) {
      openGameByUrl(item?.api_url)
    } else if (item.api_url.includes('account')) {
      if (!currentUser || !currentUser.value) {
        return openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', item?.link)
      } else {
        router.push(item.api_url)
      }
    } else {
      router.push(item.api_url)
    }
  }

  const fetchBanner = async () => {
    try {
      const { data: response } = await $axios.get(HOME_BANNER)
      if (response && response.status === 'OK') {
        if (response.data?.length > 0) {
          return response.data.filter((item: IBanner) => item.img !== null)
        }
        return []
      }
    } catch (error) {
      return []
    }
  }

  return {
    bannerData,
    fetchBanner,
    handleClickBanner
  }
}
