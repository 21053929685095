<template>
  <div class="section-sports container-custom">
    <div class="section-sports__title">
      <h3>Cá cược nổi bật</h3>
    </div>
    <div class="section-sports__content">
      <div
        v-for="(item, index) in LOBBY_SPORTS.slice(0, 4)"
        :key="index"
        class="section-sports__left--item"
        @click="() => openPage(item.link, item.isCheckLogin)"
      >
        <BaseImg :src="item.background" :alt="item.name" lazy class="background" />
        <BaseImg :src="item.img" :alt="item.name" lazy class="item" />
        <div class="inner">
          <button>Cược ngay</button>
        </div>
      </div>
      <div class="section-sports__right">
        <div
          v-for="(item, index) in LOBBY_SPORTS.slice(4, 6)"
          :key="index"
          class="section-sports__right--item"
          @click="handleClickGame(item.detail)"
        >
          <BaseImg :src="item.background" :alt="item.name" lazy class="background" />
          <div class="inner">
            <button>Cược ngay</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import BaseImg from '~/components/common/base-img.vue'
import { LOBBY_SPORTS } from '~/resources/home/section-sports'
import useNavigationGame from '~/composables/useNavigationGame'
import { useAppStore } from '~/store/app'
import { MODAL_TYPES } from '~/config/constant'

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()

const router = useRouter()
const route = useRoute()
const { openModalWithNavigate } = useModal()
const store = useAppStore()
const { isLogged } = storeToRefs(store)

const openPage = (url: string, isCheckLogin?: boolean) => {
  if (!isCheckLogin) {
    router.push(url)
    return
  }
  if (isLogged.value) {
    router.push(url)
  } else {
    navigateTo({
      query: { ...route.query, popup: MODAL_TYPES.LOGIN }
    })
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', url)
  }
}

const handleClickGame = (item: any) => {
  navigationCheckLoggedInAndOpenGame(item, undefined)
}

watch(isLogged, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      const openUrl = route.query.openUrl as string
      if (openUrl) {
        router.push({ path: openUrl })
      }
    }, 500)
  }
})
const clearQuery = () => {
  const openUrl = route.query.openUrl as string
  if (openUrl) {
    router.replace({ path: route.path, query: {} })
  }
}
onMounted(() => {
  clearQuery()
})

</script>
<style scoped lang="scss" src="public/assets/scss/components/desktop/pages/home/section-sports/index.scss"></style>
