<template>
  <nuxt-link class="section-jackpot cursor-pointer" :to="LOBBY_GAME_URLS.NO_HU">
    <BaseImg
      src="/assets/images/components/desktop/pages/home/section-jackpot/bg-jackpot.webp"
      alt="bg-jackpot"
      class="bg-jackpot"
      lazy
    />
    <div class="section-jackpot__content">
      <BaseImg
        src="/assets/images/components/desktop/pages/home/section-jackpot/title.webp"
        alt="Tổng hũ"
        class="sum-jackpot"
        lazy
      />
      <client-only>
        <AnimateCountUp :key="jackpotTotal" class="value" :number="jackpotTotal" :show-coin="false" :class="[osType]" />
      </client-only>
    </div>
  </nuxt-link>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import AnimateCountUp from '~/components/common/animate-count-up.vue'
import { useAppStore } from '~/store/app'
import BaseImg from '~/components/common/base-img.vue'
import { LOBBY_GAME_URLS } from '~~/config/page-url'

const osType = ref()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const jackpotTotal = computed(() =>
  (sumJackpot?.value?.jackpotNohu || 0) +
  (sumJackpot?.value?.jackpotIngame || 0) +
  (sumJackpot?.value?.jackpotFishing || 0)
)

</script>

<style scoped lang="scss" src="public/assets/scss/components/desktop/pages/home/section-jackpot/index.scss"></style>
