<template>
  <div class="section-sports">
    <div class="section-sports__title">
      <h3>Nổi bật</h3>
    </div>
    <div class="section-sports__content">
      <div
        v-for="(item, index) in LOBBY_SPORTS_MB"
        :key="index"
        class="section-sports--item"
        :class="item.class"
        @click="openPage(item || '')"
      >
        <BaseImg :src="item.background" :alt="item.name" class="background" lazy />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { PAGE_URLS } from '~/config/page-url'
import { useSportNavigation } from '~/composables/sports/useSportNavigation'
import { ILobbySport, LOBBY_SPORTS_MB } from '~/resources/home/section-sports'
import useNavigationGame from '~/composables/useNavigationGame'
import { MODAL_TYPES } from '~/config/constant'
import { useAppStore } from '~/store/app'

const { getUrlNccSport } = useSportNavigation()
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const router = useRouter()
const route = useRoute()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const { openModalWithNavigate } = useModal()
const openPage = async (item: ILobbySport) => {
  const url = item.link
  if (item.type === 'da-ga') {
    router.push(item.link)
    return
  }
  if (!isLogged.value) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', url)
    return
  }
  if (!url) {
    return router.push(PAGE_URLS.SCHEDULES)
  }
  const openNewTab = window.open('about:blank', '_blank')
  try {
    const response = await fetchNccSportUrl(url)
    handleResponse(response, openNewTab)
  } catch (error) {
    console.error('Error opening page:', error)
    openNewTab?.close()
  }
}
const fetchNccSportUrl = async (url: string) => {
  const { data: response } = await getUrlNccSport({}, url)
  return response
}
const handleResponse = (response: any, openNewTab: Window | null) => {
  if (!response || response.status !== 'OK') {
    return
  }
  const { maintenance, url } = response.data || {}
  if (maintenance) {
    openNewTab?.close()
    navigateTo(PAGE_URLS.MAINTAIN)
  } else if (!url || url.includes('undefined')) {
    openNewTab?.close()
    navigateTo(PAGE_URLS.HOME)
  } else if (openNewTab) {
    openNewTab.location.href = url
  }
}

watch(isLogged, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      const openUrl = route.query.openUrl as string
      const item = LOBBY_SPORTS_MB.find((sport) => sport.link === openUrl)
      if (item) {
        openPage(item)
      }
      clearQuery()
    }, 500)
  }
})

const clearQuery = () => {
  const openUrl = route.query.openUrl as string
  if (openUrl) {
    router.replace({ path: route.path, query: {} })
  }
}
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/section-sports/index.scss"></style>
