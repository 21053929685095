<template>
  <div class="home__top-banner" style="margin-bottom: -30px">
    <div class="hero-banner">
      <BaseImg
        relative-src="/assets/images/components/desktop/pages/home/section-home/resize-banner-cheat.webp"
        alt="hero-banner"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
<style scoped lang="scss" src="public/assets/scss/components/desktop/pages/home/banner/index.scss"></style>
