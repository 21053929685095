<template>
  <div class="section-hot container-custom">
    <div class="section-hot__title">
      <h3>Game hot</h3>
    </div>
    <div class="section-hot__content">
      <div v-for="(item, index) in gameHotData" :key="index" class="item-game" @click="onClickGame(item)">
        <BaseImg :src="item.img" :alt="item.name" lazy class="image-game" />
        <div class="game-hover">
          <div class="overlay">
            <div class="action" @click="onClickGame(item)">
              <span>Chơi game</span>
            </div>
            <div v-if="isTryToPlay && item.is_demo" class="try-play" @click="onClickGame(item)">Chơi thử</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import useNavigationGame from '~/composables/useNavigationGame'
import { useGameHot } from '~/composables/home/useGameHot'

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const { fetchListGameHot } = useGameHot()
const isTryToPlay = ref(true)

const onClickGame = debounce((item) => {
  navigationCheckLoggedInAndOpenGame(item)
}, 200)

const { data: gameHotData } = await useAsyncData('gameHot', async () => {
  return await fetchListGameHot()
})
</script>
<style scoped lang="scss" src="public/assets/scss/components/desktop/pages/home/section-hot/index.scss"></style>
