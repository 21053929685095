<template>
  <div class="fix-height home__top-banner">
    <div class="hero-banner">
      <Swiper ref="banner" :key="swiperKey" v-bind="swiperOption" class="mySwiper">
        <SwiperSlide v-if="dataBannerJackpot" class="swiper-slide">
          <BannerJackpot :item="dataBannerJackpot" @click="navigationCheckLoggedInAndOpenGame(dataBannerJackpot)" />
        </SwiperSlide>
        <SwiperSlide v-for="(item, key) in bannerData" :key="key" class="swiper-slide">
          <BannerItem :item="item" :index="key" @click="handleClickBanner(item)" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, EffectCoverflow } from 'swiper'
import BannerItem from '~/components/mobile/pages/home/banner/banner-item.vue'
import BannerJackpot from '~/components/mobile/pages/home/banner/banner-jackpot.vue'
import useHeroBanner from '~/composables/home/useHeroBanner'
import { useJackpot } from '~/composables/game/useJackpot'
import { useAppStore } from '~/store/app'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { jackpot } = storeToRefs(store)
const { fetchBanner, handleClickBanner } = useHeroBanner()
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const { fetchJackpot } = useJackpot()
const swiperOption = reactive({
  grabCursor: true,
  initialSlide: 0,
  observer: true,
  loop: false,
  slidesPerView: 1,
  slidesPerGroup: 1,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  centeredSlides: true,
  spaceBetween: 0,
  pagination: {
    clickable: true
  },
  modules: [Autoplay, Pagination, EffectCoverflow]
})

const dataBannerJackpot = computed(() => {
  return jackpot.value?.banner_jackpot?.[0] || null
})

// Only call the first time when loading the page to ssr the jackpot banner
await useAsyncData('jackpot', async () => {
  if (!jackpot.value?.banner_jackpot) {
    return await fetchJackpot()
  }
})

const { data: bannerData } = await useAsyncData('banner', async () => {
  return await fetchBanner()
})
const swiperKey = ref(0)

watch(
  () => bannerData.value,
  (newData) => {
    if (newData && newData.length > 0) {
      setTimeout(() => {
        swiperOption.loop = true
        swiperKey.value++
      }, 500)
    }
  },
  { immediate: true }
)
</script>

<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/banner/index.scss"></style>
