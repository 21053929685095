<template>
  <nuxt-link class="section-jackpot cursor-pointer" :to="LOBBY_GAME_URLS.NO_HU">
    <BaseImg
      src="/assets/images/components/mobile/pages/home/section-jackpot/title.webp"
      alt="sum-jackpot"
      class="sum-jackpot"
      lazy
    />
    <client-only>
      <AnimateCountUp
        :number="sumJackpot?.jackpotNohu + sumJackpot?.jackpotIngame + sumJackpot?.jackpotFishing"
        :show-coin="false"
      />
    </client-only>
  </nuxt-link>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import AnimateCountUp from '~/components/common/animate-count-up.vue'
import { useAppStore } from '~/store/app'
import BaseImg from '~/components/common/base-img.vue'
import { LOBBY_GAME_URLS } from '~~/config/page-url'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

</script>

<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/home/section-jackpot/index.scss">

</style>
